import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
    AccountApi,
    AccountOrderRequestQueryByEnum,
    AccountOrderRequestQueryDirectionEnum,
    AccountUserAddModel,
    AccountUserModifyModel,
} from '@swagger-http';

import { createRequestConfiguration } from '@tools/utils/authentication';

import useAuthContext from '@context/auth-provider';

export const useAccountGetAccountUsersQuery = (
    hasPolicy: boolean,
    page?: number,
    limit?: number,
    order?: {
        by: AccountOrderRequestQueryByEnum;
        direction: AccountOrderRequestQueryDirectionEnum;
    },
    enabled?: boolean,
    searchValue?: string,
) => {
    return useQuery({
        queryKey: [
            'getAccountUsers',
            hasPolicy,
            page,
            limit,
            order,
            searchValue,
        ],
        enabled,
        queryFn: async () => {
            if (!hasPolicy) return;

            const response = await new AccountApi(
                createRequestConfiguration(),
            ).accountGetAccountUsers({
                page,
                limit,
                filterValue: searchValue,
                order,
            });

            return response;
        },
    });
};

export const useCreateUser = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['createUser'],
        mutationFn: async (payload: AccountUserAddModel) => {
            const response = new AccountApi(
                createRequestConfiguration(),
            ).accountAddAccountUser({ accountUserAddModel: payload });
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getAccountUsers'] });
        },
    });
};

export const useUpdateUser = (id: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['updateUser'],
        mutationFn: async (payload: AccountUserModifyModel) => {
            const response = new AccountApi(
                createRequestConfiguration(),
            ).accountModifyAccountUser({
                id: id,
                accountUserModifyModel: payload,
            });
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getAccountUsers'] });
        },
    });
};

const removeAccountUser = (id: string) => {
    return new AccountApi(
        createRequestConfiguration(),
    ).accountRemoveAccountUser({
        id: id,
    });
};

const removeAccountUserMultiFactorAuth = (id: string) => {
    return new AccountApi(
        createRequestConfiguration(),
    ).accountRemoveUserMultiFactorAuth({
        id: id,
    });
};

export const useRemoveUser = (id: string) => {
    const queryClient = useQueryClient();
    const { hasMultiFactorAuth } = useAuthContext();
    return useMutation({
        mutationKey: ['removeUser'],
        mutationFn: async () => {
            if (hasMultiFactorAuth) {
                return removeAccountUserMultiFactorAuth(id);
            } else {
                return removeAccountUser(id);
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getAccountUsers'] });
        },
    });
};
